
import {IonIcon, IonLabel, IonPage, IonTabBar, IonTabButton, IonTabs,IonBadge} from '@ionic/vue';
import {addCircleOutline, alertCircleOutline,logInOutline} from 'ionicons/icons';
import {getActiveRatingsCount} from "@/util/ResultService";

export default {
    name: 'Dashboard',
    components: {IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage, IonBadge},
    setup() {
        return {
            addCircleOutline,
            alertCircleOutline,
            logInOutline
        }
    },
    computed: {
        getOpenRatingsCount(): number{
            return getActiveRatingsCount()
        }
    }
}
